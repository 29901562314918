// @keyframes smoke {
//     from {
//         left: -50px;
//     }

//     to {
//         left: 50px;
//     }
// }

// .smoke {
//     animation: smoke 5s infinite;
// }

.truck {
    width: 200px;
    padding-top: 2rem;
}

.tree {
    width: 50px;
    padding-top: 1rem;
}

// .slideOutLeft {
//     animation-duration: 2s;
// }

@-webkit-keyframes slideInOut {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes slideInOut {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
    }
}

.slideInOut {
    -webkit-animation-name: slideInOut;
    -webkit-animation-timing-function: linear;
    -webkit-animation-duration: 10s;
    animation-name: slideInOut;
    animation-timing-function: linear;
    animation-duration: 10s;
    z-index: -2;
    .truck {
        float: right;
    }
}

.animated {
    animation-timing-function: linear;
}

.delay-6s {
    animation-delay: 6s;
}

.delay-7s {
    animation-delay: 7s;
}

.delay-8s {
    animation-delay: 8s;
}

.delay-9s {
    animation-delay: 9s;
}

.delay-10s {
    animation-delay: 10s;
}

.delay-11s {
    animation-delay: 11s;
}

.delay-12s {
    animation-delay: 12s;
}

.delay-13s {
    animation-delay: 13s;
}

.delay-14s {
    animation-delay: 14s;
}

.delay-15s {
    animation-delay: 15s;
}

.delay-0 {
    animation-delay: .005s;
}

.delay-1 {
    animation-delay: .01s;
}

.delay-2 {
    animation-delay: .02s;
}

.delay-3 {
    animation-delay: .03s;
}