a {
    color: $blue;
    text-decoration: underline;

    &:hover {
        color: $blue;
    }
}

.link {
    text-decoration: underline;
    cursor: pointer;
}