p {
    hyphens: none;
}
h1, h2 {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    // font-size: 3.125rem; //50px
}
h2 {
    font-size: 2.5rem;
}
h3 {
    font-weight: 300;
    font-size: 1.5rem;
}
.fs-14 {
    font-size: 14px;
}
.fs-20 {
    font-size: 20px;
}
.fs-24 {
    font-size: 24px;
}
.fs-32 {
    font-size: 32px;
}
.fs-100 {
    font-size: 100px;
}
.lh-1 {
    line-height: 1;
}