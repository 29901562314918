@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS */

	.container-fluid {
		align-items: center;
	}
	.z-0 {
		z-index: 0;
	}

	.d-flex {
		width: 100%;
		display: flex;
		//vertical-align: middle !important;
		text-align: center;

		.row {
			display: flex;
		}

		.col-md {
			margin-top: 130px;
		}
	}
	footer {
		&.align-items-center {
			.row {
				margin-top: 10%;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	#counter {
		padding-top: 3rem;
	}
}

.card-deck {
	display: block;

	.card {
		height: 100%;
		width: 30%;
		float: left;
	}
}
.text-center {
	vertical-align: middle !important;
}

.position-absolute {
	left: 0;
}

.mt-auto {
	margin-top: inherit !important;
}

input {
	padding: 0 1.5rem !important;
	height: 3.5rem !important;
}

// .form-inline {
// 	.row {
// 		display: block;
// 		width: 100%;

// 		.col {
// 			float: left;
// 		}
// 	}
// }

@supports (-ms-accelerator: true) {
	/* Edge 12+ CSS */
}

@supports (-ms-ime-align: auto) {
	/* Edge 16+ CSS */
}
