section {
    overflow: hidden !important;
    clip: rect(auto, auto, auto, auto);
    position: relative;
    z-index: 1;
}
.mh-450 {
    min-height: 450px;
}
.mh-570 {
    min-height: 570px;
}
.z-0 {
    z-index: -1;
}
.z-1 {
    z-index: 1;
}