#country-select-wrapper {
    display: inline-block;
    position: relative;
    border: 3px solid  $green;
    border-radius: 5px;
    box-sizing: border-box;
    &::after {
        content: "";
        background-image: url(../img/chevron.svg);
        background-size: cover;
        width: 20px;
        height: 20px;
        position: absolute;
        color: $green;
        top: .9em;
        right: 1rem;
        z-index: 5;
        pointer-events: none;
    }
    select {
        font-family: Rubik, sans-serif;
        font-size: 20px;
        line-height: 20px;
        padding: 1rem 0;
        color:  $green;
        width: 262px;
        -webkit-appearance: none;
        -ms-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        outline: 0;
        background-color: transparent;
        position: relative;
        cursor: pointer;
        z-index: 10;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: center;
    }
}

.transform-unset {
    text-transform: unset;
}

#transports {
    font-family: Rubik, sans-serif;
    font-size: 20px;
    padding: 1rem 0;
    outline: 0;
    border-radius: 5px;
    border: 3px solid $green;
    background: none;
    color: $green;
    -moz-appearance: textfield;
    text-align: center;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::placeholder {
        color: $green;
    }
}

.calculator-output {
    color:  $green;
    font-size: 2rem;
    font-weight: 700;
}

.align-calculator-outputs {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
}

.aligned-output {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.aligned-output-image {
    margin-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: flex-end;
}

.aligned-output-image>img {
    width: auto;
    height: 50px;
}

.aligned-output:last-of-type>.aligned-output-image>img {
    height: 70px;
}

.aligned-output-content {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

.booknow-btn {
    font-weight: 700;
    font-size: 1.3rem;
    text-decoration: none !important;
    color: $green;
    background-color: $yellow;
    border-radius: 5px;
    padding: 15px 30px;
}

@media only screen and (max-width : 768px) {
    .align-calculator-outputs {
        flex-flow: row wrap;
    }

    .aligned-output {
        flex-basis: auto;
        width: 100%;
    }
}