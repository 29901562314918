.bg-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.bg-cover-right {
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
}

.trees {
    &:after {
        content: url(../img/trees.svg);
        display: block;
        overflow: hidden;
        margin: 0 auto;
        text-align: center;
        bottom: -8px;
        position: relative;
        min-width: 100%;
    }
}