#counter {
	max-width: 580px;
	margin: 0 auto;

	.col {
		@extend .d-flex;
		@extend .align-items-center;
		@extend .justify-content-center;
		background: $yellow;
		max-width: 110px;
		height: 110px;
		border-radius: 50%;
		margin: 0.2rem;
		font-size: 4rem;
		font-weight: 500;
		font-family: Helvetica, Arial, sans-serif;

		p {
			margin-top: 1rem;
		}
	}

	@include media-breakpoint-only(md) {
		padding: 0 15%;
	}

	@include media-breakpoint-down(md) {
		.col {
			max-width: 55px;
			height: 55px;
			font-size: 2rem;
		}
	}
}