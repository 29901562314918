.accordion {
    cursor: pointer;

    .card,
    .card-header {
        border: none;
        border-radius: 0;
        background-color: $gray-100;
    }
    .card {
        border-top: 1px solid $green;
        .collapse.show {
            background-color: $gray-100;
        } 
        [aria-expanded="false"] {
            background-color: $white;
        } 
        // &:nth-of-type(even) {
            //     background-color: $grey-light;
            // }
            
        .collapsed {
            .left-bar:after {
                transform: rotate(0deg);
            }

            .right-bar:after {
                transform: rotate(0deg);
            }
        }
    }

    .arrow-icon {
        right: 3rem;
    }

    @include media-breakpoint-only(xs) {
        p {
            padding-right: 2rem;
        }

        .arrow-icon {
            right: 2rem;
        }
    }
}