.arrow-icon {
    top: 0.5em;
    display: block;
    position: absolute;
    border-radius: 4px;
}

.left-bar {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 20px;
    height: 0;
    display: block;
    transform: rotate(45deg);
    float: right;
    border-radius: 2px;
    left: 1px;

    &:after {
        content: "";
        background-color: $green;
        width: 20px;
        height: 1px;
        display: block;
        float: right;
        border-radius: 6px 10px 10px 6px;
        transition: all $duration $easing;
        z-index: -1;
        transform-origin: center center;
        transform: rotate(-90deg);
    }
}

.right-bar {
    position: absolute;
    background-color: transparent;
    top: 0px;
    left: 14px;
    width: 20px;
    height: 0;
    display: block;
    transform: rotate(-45deg);
    float: right;
    border-radius: 2px;

    &:after {
        content: "";
        background-color: $green;
        width: 20px;
        height: 1px;
        display: block;
        float: right;
        border-radius: 10px 6px 6px 10px;
        transition: all $duration $easing;
        z-index: -1;
        transform-origin: center center;
        transform: rotate(90deg);
    }
}