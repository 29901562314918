// Colors
$yellow: #FFEF1F;
$green: #004237;
$blue: #0D505F;
$grey: #5C5C5C;
$grey-light: #F2F2F2;
// $blue-light: #40B4DC;
// $blue-dark: #010305;
$red: #E80029;
$black: #000;
$white: #fff;
$grey-x-light:#f1f1f1;
// $grey-light:#d2d2d2;
$grey-medium:#8c8c8c;
$grey-dark:#5a5a5a;
$grey-form:#C8C8C8;

//Grid breakpoints
$grid-breakpoints: (xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1140px) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

//Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900&display=swap');

body {
    font-family: 'Heebo', sans-serif;
}

// Animation properties
$easing: cubic-bezier(.25, 1.7, .35, .8);
$duration: 0.5s;