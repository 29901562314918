.btn {
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
}

.btn-primary {
    border-radius: 3px;
    padding: .6rem 1.5rem;
    border-width: 2px;
    background: $yellow;
    border-color: transparent;
    color: $green;
    text-decoration: none;

    &:hover {
        background: $yellow;
        border-color: transparent;
        color: $green;
        text-decoration: none;
    }
}

.btn-outline-primary {
    border-radius: 50px;
    border-width: 2px;
    border-color: $yellow;
    color: $yellow;
    text-decoration: none;

    &:hover {
        background: $yellow;
        border-color: $yellow;
        color: $green;
        text-decoration: none;
    }
}