.text-blue {
    color: $blue;
}

.text-green {
    color: $green;
}

.text-yellow {
    color: $yellow;
    a {
        color: $yellow;
    }
}

.text-grey {
    color: $grey;
}

.bg-blue {
    background-color: $blue;
}

.bg-green {
    background-color: $green;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-grey {
    background-color: $grey;
}

.bg-lightgrey {
    background-color: $grey-light;
}

hr {
    border: .5px solid $green;
    width: 50%;
}